import React, {useState} from "react";
import {Link} from "react-router-dom";
import PopUpForm from "../../../components/Molecules/PopUp/PopUpForm";
import FormEditCommentBills from "../../../components/Molecules/Form/FormEditCommentBills";
import FormAssetGenerator from "../../../components/Molecules/Form/FormAssetGenerator";
import exportFileService from "../../../services/ExportFileService";
import moment from "moment";

export const InvoicesDownPayments = [
	{
		Header: "Numéro facture",
		accessor: "reference",
	},
	{
		Header: "Nom client",
		accessor: "client.niceNames",
		Cell: cellInfo => {
			return (
				<Link to={`/client/${cellInfo.row.original.client.id}`}>{cellInfo.row.original.client.niceNames}</Link>
			)
		}
	},
	{
		Header: "Numéro du client",
		accessor: "client.reference",
	},
	{
		Header: "Montant HT",
		accessor: "amount",
		Cell: cellInfo => {
			return (
				<div>{cellInfo.row.original.amount.toFixed(2)} €</div>
			)
		}
	},
	{
		Header: "Montant TTC",
		accessor: "amountWithTax",
		Cell: cellInfo => {
			return (
				<div>{cellInfo.row.original.amountWithTax.toFixed(2)} €</div>
			)
		}
	},
	{

		Header: "Mode de paiement",
		accessor: "",
		filter: ({value}) => !!value,
		Cell: cellInfo => {
			return (
				<div className={"green-info"}>{cellInfo.row.original.payment.niceMethod}</div>
			)
		}
	},
	{
		Header: "Date",
		accessor: "createdAt",
		Cell: cellInfo => {
			let date = new Date(cellInfo.row.original.createdAt)
			return (
				date.toLocaleDateString()
			)
		}
	},
	{
		Header: "Commentaire",
		accessor: "comment",
	},
	{
		Header: "Actions",
		accessor: "",
		Cell: cellInfo => {

			const [show, setShow] = useState(false);
			const [showAssetGenerator, setShowAssetGenerator] = useState(false);

			const DisplayEditModalBills = () => {
				setShow(!show)
			}

			const DisplayAssetGenerator = () => {
				setShowAssetGenerator(!showAssetGenerator)
			}

			const downloadFile = () => {
				const date = moment().format("L").split("/").join("-")
				exportFileService.exportFile(`/invoices/${cellInfo.row.original.id}`, `export-facture-${date}`, {}, "pdf")
			}

			return (
				<>
					<div className={"actions-controller"}>
						<img src={"/images/pictos/bdd-blue.svg"} onClick={() => setShowAssetGenerator(true)}
						     title={"Générer un avoir"}/>
						{/*<img src={"/images/pictos/file.svg"}/>*/}
						<img src={"/images/pictos/download.svg"} onClick={() => downloadFile()} title={"Télécharger la facture"}/>
						<img onClick={() => setShow(true)} src={"/images/pictos/pencil-blue.svg"}
						     title={"Modifier le commentaire"}/>
					</div>

					{
						showAssetGenerator ? (
							<PopUpForm large={true} onClick={DisplayAssetGenerator} show={showAssetGenerator}
							           title={`Génération de l'avoir de ${cellInfo.row.original.client.niceGender} ${cellInfo.row.original.client.niceNames} - ${cellInfo.row.original.client.reference}`}
							           form={<FormAssetGenerator row={cellInfo.row.original} setShow={setShowAssetGenerator}/>}
							/>
						) : null
					}

					{
						show ? (
							<PopUpForm large={true} onClick={DisplayEditModalBills} show={show} title={"Modifier le commentaire"}
							           form={<FormEditCommentBills id={cellInfo.row.original.id} setShow={setShow}
							                                       comment={cellInfo.row.original.comment}/>}
							/>
						) : null
					}
				</>
			)
		}
	},
]
