import React, {createContext, useEffect, useState} from 'react';
import moment from "moment";
import {CONTROL_COLUMNS} from "../../../../../config/ArrayColumns/Engineer/ControlColumns";
import TableTitle from "../../../../../components/Molecules/Tables/TalbeTitle";
import FilterTwoSelectsDatepicker from "../../../../../components/Molecules/Filter/FilterTwoSelectsDatepicker";
import {toast} from "react-toastify";
import DirRespService from "../../../../../services/Ing/DirResp/DirRespService";
import {dataRegionsArray} from "../../../../../utils/RegionsUtils";

export const StateRequest = createContext()
const ControleViewIngDir = () => {

	const [controls, setControls] = useState([]);

	const DisplayToast = (type) => {
		if (type === "patch") {
			toast.success("L'étude a bien été archivée")
		} else if (type === "error") {
			toast.error("Une erreur est survenue")
		}
		setStateRequest("")
	}
	const [stateRequest, setStateRequest] = useState("");
	const options = dataRegionsArray
	const [dateStart, setDateStart] = useState("");
	const [dateEnd, setDateEnd] = useState("");
	const [date, setDate] = useState(new Date());
	const [userFilter, setUserFilter] = useState("")
	const [region, setRegion] = useState("");
	const [collaborators, setCollaborators] = useState([]);

	const [loading, setLoading] = useState(true)

	const changeRegionFilter = (event) => {
		if (event.target.value === "Toutes les agences") {
			setRegion("")
		} else {
			setRegion(event.target.value)
		}
	}

	const changeDate = (date) => {
		setDate(date)
		setDateStart(moment(date[0]).format("YYYY-MM-DD"))
		setDateEnd(moment(date[1]).format("YYYY-MM-DD"))
	}

	const changeCollaboratorFilter = (event) => {
		if (event.target.value === "Tous les collaborateurs") {
			setUserFilter("")
		} else {
			setUserFilter(event.target.value)
		}
	}

	const resetDate = () => {
		setDate(new Date())
		setDateStart("")
		setDateEnd("")
	}

	const getControls = async () => {
		const request = await DirRespService.getControls({
			"region": region,
			"users.id": userFilter,
			"createdAt[after]": dateStart,
			"createdAt[before]": dateEnd,
			"pagination": "true",
		})
		if (request.status === 200) {
			if (request.data['hydra:member'].length === 0) {
				toast.info("Aucun contrôle n'a été trouvé")
			} else {
				setLoading(false)
			}
			setControls(request.data);
		} else {
			toast.error("Une erreur est survenue lors de la récupération des contrôles")
		}
	}

	const getCollaborators = async () => {
		const request = await DirRespService.getAllCollaborators({regions: region})
		if (request.request.status === 200) {
			setCollaborators(request.data['hydra:member'])
		} else {
			toast.error("Une erreur est survenue lors de la récupération de liste des collaborateurs")
		}
	}

	useEffect(() => {
		setLoading(true)
		if (stateRequest !== "") {
			DisplayToast(stateRequest)
		}
		getControls()
		getCollaborators()

	}, [region, userFilter, dateStart, dateEnd, stateRequest])


	return (
		<StateRequest.Provider value={{stateRequest, setStateRequest}}>
			<div className="mbe-section">
				<FilterTwoSelectsDatepicker
					defaultValue={"Toutes les agences"}
					options={options}
					firstValue={options}
					secondDefaultValue={"Tous les collaborateurs"}
					secondOptions={collaborators.map(collaborators => collaborators.lastname)}
					secondValue={collaborators.map(collaborators => collaborators.id)}
					onChange={changeRegionFilter}
					onChangeDate={(date) => changeDate(date)}
					onSecondChange={changeCollaboratorFilter}
					date={date}
					resetDateSelected={resetDate}

				/>
				{
					!loading ? (


						<TableTitle title={"Contrôles"} className={"section-array-revival mbe-section"} data={controls}
						            columnTable={CONTROL_COLUMNS}/>
					) : null
				}


			</div>
		</StateRequest.Provider>
	);
};

export default ControleViewIngDir;
