import React from "react";

const ProjectNiceType = ({ type, value }) => {
  let ProjectNiceTypeInfo = null;

  switch (type) {
    case "project-cn":
      ProjectNiceTypeInfo = {
        cardImg: (
          <img
            src="/images/medias/typologies/cards/construction-neuve.jpeg"
            alt="construction neuve image"
          />
        ),
        bannerImg: (
          <img
            src="/images/medias/typologies/banners/construction-neuve.jpeg"
            alt="construction neuve image"
          />
        ),
        // time: moment().add(25, 'days').format('DD/MM/YYYY')
        time: "25",
      };
      return ProjectNiceTypeInfo[value];
    case "project-cdm":
      ProjectNiceTypeInfo = {
        cardImg: (
          <img
            src="/images/medias/typologies/cards/creation-de-mezzanine.jpeg"
            alt="creation de mezzanine image"
          />
        ),
        bannerImg: (
          <img
            src="/images/medias/typologies/banners/creation-de-mezzanine.jpeg"
            alt="creation de mezzanine image"
          />
        ),
        // time: moment().add(10, 'days').format('DD/MM/YYYY')
        time: "10",
      };
      return ProjectNiceTypeInfo[value];
    case "project-ext":
      ProjectNiceTypeInfo = {
        cardImg: (
          <img
            src="/images/medias/typologies/cards/extension.png"
            alt="extension image"
          />
        ),
        bannerImg: (
          <img
            src="/images/medias/typologies/banners/extension.jpeg"
            alt="extension image"
          />
        ),
        // time: moment().add(15, 'days').format('DD/MM/YYYY')
        time: "15",
      };
      return ProjectNiceTypeInfo[value];
    case "project-omp":
    case "project-omsp":
      ProjectNiceTypeInfo = {
        cardImg: (
          <img
            src="/images/medias/typologies/cards/ouverture-de-mur-porteur.png"
            alt="ouverture de mur porteur image"
          />
        ),
        bannerImg: (
          <img
            src="/images/medias/typologies/banners/ouverture-de-mur-porteur.jpg"
            alt="ouverture de mur porteur image"
          />
        ),
        // time: moment().add(10, 'days').format('DD/MM/YYYY')
        time: "10",
      };
      return ProjectNiceTypeInfo[value];
    case "project-sur":
      ProjectNiceTypeInfo = {
        cardImg: (
          <img
            src="/images/medias/typologies/cards/surelevation.jpeg"
            alt="surelevation image"
          />
        ),
        bannerImg: (
          <img
            src="/images/medias/typologies/banners/surelevation.jpeg"
            alt="surelevation image"
          />
        ),
        // time: moment().add(15, 'days').format('DD/MM/YYYY')
        time: "15",
      };
      return ProjectNiceTypeInfo[value];
    case "project-diag-str":
    case "project-diag-omp":
    case "project-diag-omsp":
      ProjectNiceTypeInfo = {
        cardImg: (
          <img
            src="/images/medias/typologies/cards/diagnostic-structure.jpeg"
            alt="diagnostic structure image"
          />
        ),
        bannerImg: (
          <img
            src="/images/medias/typologies/banners/diagnostic-structure.jpeg"
            alt="diagnostic structure image"
          />
        ),
        // time: moment().add(5, 'days').format('DD/MM/YYYY')
        time: "5",
      };
      return ProjectNiceTypeInfo[value];
      case "project-rso":
      ProjectNiceTypeInfo = {
        cardImg: (
          <img
            src="/images/medias/typologies/cards/reprise-en-sous-œuvre.jpg"
            alt="Reprise en sous-œuvre"
          />
        ),
        bannerImg: (
          <img
            src="/images/medias/typologies/banners/reprise-en-sous-œuvre.jpg"
            alt="Reprise en sous-œuvre"
          />
        ),
        // time: moment().add(5, 'days').format('DD/MM/YYYY')
        time: "5",
      };
      return ProjectNiceTypeInfo[value];
      case "project-ddc":
      ProjectNiceTypeInfo = {
        cardImg: (
          <img
            src="/images/medias/typologies/cards/depose-cheminee.jpeg"
            alt="Dépose de cheminée"
          />
        ),
        bannerImg: (
          <img
            src="/images/medias/typologies/banners/depose-cheminee.jpeg"
            alt="Dépose de cheminée"
          />
        ),
        // time: moment().add(5, 'days').format('DD/MM/YYYY')
        time: "5",
      };
      return ProjectNiceTypeInfo[value];
    case "project-dcp":
      ProjectNiceTypeInfo = {
        cardImg: (
          <img
            src="/images/medias/typologies/cards/determination-capacite-portante.jpeg"
            alt="determination capacite portante image"
          />
        ),
        bannerImg: (
          <img
            src="/images/medias/typologies/banners/determination-capacite-portante.jpeg"
            alt="determination capacite portante image"
          />
        ),
        // time: moment().add(10, 'days').format('DD/MM/YYYY')
        time: "10",
      };
      return ProjectNiceTypeInfo[value];
    case "project-diag-fiss":
      ProjectNiceTypeInfo = {
        cardImg: (
          <img
            src="/images/medias/typologies/cards/diagnostic-fissuration.jpeg"
            alt="diagnostic fissuration image"
          />
        ),
        bannerImg: (
          <img
            src="/images/medias/typologies/banners/diagnostic-fissuration.jpeg"
            alt="diagnostic fissuration image"
          />
        ),
        // time: moment().add(10, 'days').format('DD/MM/YYYY')
        time: "10",
      };
      return ProjectNiceTypeInfo[value];
    case "project-diag-pt":
      ProjectNiceTypeInfo = {
        cardImg: (
          <img
            src="/images/medias/typologies/cards/diagnostic-post-travaux.jpeg"
            alt="diagnostic post travaux image"
          />
        ),
        bannerImg: (
          <img
            src="/images/medias/typologies/banners/diagnostic-post-travaux.jpeg"
            alt="diagnostic post travaux image"
          />
        ),
        // time: moment().add(10, 'days').format('DD/MM/YYYY')
        time: "10",
      };
      return ProjectNiceTypeInfo[value];
    case "project-mdc":
      ProjectNiceTypeInfo = {
        cardImg: (
          <img
            src="/images/medias/typologies/cards/modification-de-charpente.jpeg"
            alt="modification de charpente image"
          />
        ),
        bannerImg: (
          <img
            src="/images/medias/typologies/banners/modification-de-charpente.jpeg"
            alt="modification de charpente image"
          />
        ),
        // time: moment().add(10, 'days').format('DD/MM/YYYY')
        time: "10",
      };
      return ProjectNiceTypeInfo[value];

      case "project-pdb":
        ProjectNiceTypeInfo = {
          cardImg: (
            <img
              src="/images/medias/typologies/cards/pathologie-de-batiment.jpg"
              alt="pathologie du batiment image"
            />
          ),
          bannerImg: (
            <img
              src="/images/medias/typologies/banners/pathologie-de-batiment.jpg"
              alt="pathologie du batiment image"
            />
          ),
          // time: moment().add(10, 'days').format('DD/MM/YYYY')
          time: "10",
        };
        return ProjectNiceTypeInfo[value];

        case "project-cheminee":
        ProjectNiceTypeInfo = {
          cardImg: (
            <img
              src="/images/medias/typologies/cards/pathologie-de-batiment.jpg"
              alt="pathologie du batiment image"
            />
          ),
          bannerImg: (
            <img
              src="/images/medias/typologies/banners/pathologie-de-batiment.jpg"
              alt="pathologie du batiment image"
            />
          ),
          // time: moment().add(10, 'days').format('DD/MM/YYYY')
          time: "10",
        };
        return ProjectNiceTypeInfo[value];
    case "project-pdp":
    case "project-rdp":
      ProjectNiceTypeInfo = {
        cardImg: (
          <img
            src="/images/medias/typologies/cards/renforcement-de-plancher.jpeg"
            alt="renforcement de plancher image"
          />
        ),
        bannerImg: (
          <img
            src="/images/medias/typologies/banners/renforcement-de-plancher.jpeg"
            alt="renforcement de plancher image"
          />
        ),
        // time: moment().add(10, 'days').format('DD/MM/YYYY')
        time: "10",
      };
      return ProjectNiceTypeInfo[value];
    case "project-odp":
      ProjectNiceTypeInfo = {
        cardImg: (
          <img
            src="/images/medias/typologies/cards/ouverture-de-plancher.jpeg"
            alt="ouverture de plancher image"
          />
        ),
        bannerImg: (
          <img
            src="/images/medias/typologies/banners/ouverture-de-plancher.jpeg"
            alt="ouverture de plancher image"
          />
        ),
        // time: moment().add(10, 'days').format('DD/MM/YYYY')
        time: "10",
      };
      return ProjectNiceTypeInfo[value];
    case "project-suivi":
      ProjectNiceTypeInfo = {
        cardImg: (
          <img
            src="/images/medias/typologies/cards/suivi-de-travaux.png"
            alt="suivi de travaux image"
          />
        ),
        bannerImg: (
          <img
            src="/images/medias/typologies/banners/suivi-travaux.png"
            alt="suivi de travaux image"
          />
        ),
        // time: moment().add(15, 'days').format('DD/MM/YYYY')
        time: "15",
      };
      return ProjectNiceTypeInfo[value];
    default:
      return (
        <img
          src="/images/medias/typologies/banners/suivi-travaux.png"
          alt={`${type} image`}
        />
      );
  }
};

export default ProjectNiceType;
