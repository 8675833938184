import React, {useState} from "react";
import { Link } from "react-router-dom";
import PopUpControlEngineer from "../../../components/Molecules/PopUp/PopUpControlEngineer";
import moment from "moment";

export const CONTROL_COLUMNS = [
	{
		Header: "Numéro client",
		accessor: "client.reference"
	},
	{
		Header: "Référence projet",
		accessor: "reference",
	},
	{
		Header: "Nom client",
		accessor: "client.niceNames",
		Cell: cellInfo => {
			return (
				<Link to={`/client/${cellInfo.row.original.client.id}`}>
					{cellInfo.row.original.client.niceNames}
				</Link>
			)
		}
	},
	{
		Header: "Ingénieur diagnostiqueur",
		accessor: "ingDiag.niceNames",
	},
	{
		Header: "Rédacteur",
		accessor: "ingAff.niceNames",
	},
	{
		Header: "Indicateurs",
		accessor: "diagnostic",
		Cell: cellInfo => {
			if (cellInfo.row.original.studyStatus === "ok") {
				return (
					<div className="badge badge-success">
						<div className={"pin-success"}></div>
						{cellInfo.row.original.niceStudyStatus}
					</div>
				)
			} else if (cellInfo.row.original.studyStatus === "minor") {
				return (
					<div className="badge badge-waiting">
						<div className={"pin-wainting"}></div>
						{cellInfo.row.original.niceStudyStatus}
					</div>
				)
			} else {
				return (
					<div className="badge badge-error">
						<div className={"pin-error"}></div>
						{cellInfo.row.original.niceStudyStatus ? cellInfo.row.original.niceStudyStatus : "N/A"}</div>
				)
			}
		}
	},
	{
		Header: "Date de rendu",
		accessor: "study.endAt",
		Cell: cellInfo => {
			let date = "N/A";
			if (cellInfo.row.original.study) {
				date = new Date(cellInfo.row.original.study.endAt);
				date = moment(date).format("DD/MM/YYYY");
			}
			return date
		}
	},
	{
		Header: "Actions",
		accessor: "",
		Cell: cellInfo => {
			const [showPopUp, setShowPopUp] = useState(false)
			return (
				<>
					<img onClick={() => setShowPopUp(true)} src="/images/pictos/box.svg" title={"Débloquer l'étude"}/>
					{
						showPopUp ? (
							<PopUpControlEngineer id={cellInfo.row.original.study.id} onClick={() => setShowPopUp(false)}/>
						) : null
					}
				</>
			)
		}
	}
]
