import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Skeleton from 'react-loading-skeleton';

import Projectheader from '../../Partials/ProjectHeader';
import ProjectNav from '../../Partials/ProjectNav';
import ButtonSubmit from '../../../../components/Atomes/Buttons/ButtonSubmit';
import ProjectStagePaymentVir from './Virement/PaymentVir';

import {ProjectStageService} from '../../../../services/ProjectStageService';
import ProjectSectionMarketplace from '../../Partials/SectionMarketplace';

const ProjectStagePayment = () => {

	const navigate = useNavigate();
	const {projectId} = useParams();
	const [projectStepVerificator, setProjectStepVerificator] = useState(false);
	const [project, setProject] = useState(null);
	const [isCbSelected, setIsCbSelected] = useState(false);
	const [isVirSelected, setIsVirSelected] = useState(false);
	const [isWaitingVirValidation, setIsWaitingVirValidation] = useState(false);
	const [invoice, setInvoice] = useState(null);
	const [depositAmount, setDepositAmount] = useState(0);

	useEffect(() => {
		window.scrollTo(0, 0);
		getProjectData();
	}, []);

	const getProjectData = async () => {
		let projectInfos = await ProjectStageService.getSingleProject(projectId);
		if (projectInfos.stage === 'ADMIN_payment-follow-up' || projectInfos.stage === 'ADMIN_payment-validation' || projectInfos.stage === 'COM_payment') {
			projectInfos.invoices.forEach(element => {
				if (element.type === 'bill-invoice-final') {
					if (element.payment?.status === 'paid' && projectInfos.stageStatus !== 'problem') {
						return navigate("/projet/" + projectId + "/?event=payment_complete", {replace: true});
					}
					setInvoice(element);
					setDepositAmount(element.amountWithTax);
				}
			});
			if (projectInfos.stage === 'ADMIN_payment-validation' && projectInfos.stageStatus === 'processing') {
				setIsWaitingVirValidation(true);
			}
			setProject(projectInfos);
			setProjectStepVerificator(true);
		} else {
			navigate("/projet/" + projectId, {replace: true});
		}
	}

	// const addExtraPlan = (e) => {
	//   let extraPriceWithTva = 150.00 * 1.2;
	//   if (e.target.checked) {
	//     setPrice(price + extraPriceWithTva);
	//   } else {
	//     setPrice(price - extraPriceWithTva);
	//   }
	// }

	// const addExtraStudy = (e) => {
	//   let extraPriceWithTva = 15.00 * 1.2;
	//   if (e.target.checked) {
	//     setPrice(price + extraPriceWithTva);
	//   } else {
	//     setPrice(price - extraPriceWithTva);
	//   }
	// }

	const handleCbChange = () => {
		setIsCbSelected(!isCbSelected);
		setIsVirSelected(false);
	}

	const handleVirChange = () => {
		setIsVirSelected(!isVirSelected);
		setIsCbSelected(false);
	}

	const handleValidMethod = async () => {
		if (isCbSelected) {
			let redirectUrl = `${process.env.REACT_APP_PROD_URL}/projet/${projectId}?event=payment_complete`;
			let body = {
				"redirectUrl": redirectUrl
			}
			let request = await ProjectStageService.postPayCb(invoice.id, body)
			if (request.status === 201) {
				window.location.replace(request.data.payment.paymentUrl);
			}
		}
	}

	return (
		<>
			{
				!projectStepVerificator ? (
					<>
						<Skeleton height={300}/>
						<Skeleton height={40}/>
					</>
				) : (
					<>
						<Projectheader
							project={project}
						/>

						<section className='project-container'>
							<ProjectNav
								step={5}
								visit={project.visitType}
							/>
							{
								!isWaitingVirValidation && (
									<div className="project-container-step__header">
										<h3 className="color-black">Choisissez votre mode de paiement pour le paiement final</h3>
										<p className="text-primary">
										Le paiement final correspond à 50 % du montant du devis que vous avez signé. À la suite du 
										paiement final, vous pourrez accéder à votre dossier d'étude structure pour le télécharger. 
										Si vous avez des demandes de modification, vous pouvez faire votre demande à l'ingénieur chargé(e) 
										d'affaires depuis le chat interne en bas à droite. Vous pouvez régler en carte bancaire ou en virement bancaire.
										</p>
									</div>
								)
							}

							{
								isWaitingVirValidation && (
									<>
										<div className="project-container-step__header">
											<h3 className="color-black">Nous vérifions votre preuve de paiement</h3>
											<p className="text-primary">
												Cette étape peut prendre jusqu'à 24 heures. Vous recevrez un e-mail dès que votre paiement sera
												validé.
												Vous pourrez ensuite passer à l'étape de prise de rendez-vous avec nos ingénieurs.
											</p>
										</div>
										<ProjectSectionMarketplace />
									</>
								)
							}

							{
								!isWaitingVirValidation && (
									<>
										{/* <div className='project-step-payment__extras'>
                <h4>Souhaitez-vous recevoir les plans et le dossier d'étude structure au format papier ?</h4>
                <div>
                  <input type='checkbox' name='extraPlan' id='extraPlan' onChange={addExtraPlan} />
                  <label htmlFor='extraPlan' className='text-primary color-dark-grey'>Recevoir les plans en version papier au format A0 (150,00 € HT)</label>
                </div>
                <div>
                  <input type='checkbox' name='extraStudy' id='extraStudy' onChange={addExtraStudy} />
                  <label htmlFor='extraStudy' className='text-primary color-dark-grey'>Recevoir mon dossier d'étude structure en version papier (+15,00 € HT)</label>
                </div>
              </div> */}

										<div className='project-step-payment__selectMethod'>
											<h4 className='color-black'>Montant final : {depositAmount} € TTC</h4>
											<div className={`project-step-choice ${isCbSelected ? 'valid' : ''}`}>
												<label htmlFor="cb" className='text-tertiary color-dark-grey'>
													Régler par carte bancaire
												</label>
												<input
													type='radio' id='cb' name="method"
													onChange={handleCbChange} checked={isCbSelected}
												/>
											</div>
											<div className={`project-step-choice ${isVirSelected ? 'valid' : ''}`}>
												<label htmlFor="vir" className='text-tertiary color-dark-grey'>
													Régler par virement bancaire
												</label>
												<input
													type='radio' id='vir' name="method"
													onChange={handleVirChange} checked={isVirSelected}
												/>
											</div>
										</div>

										{
											isCbSelected && (
												<div className='project-step-payment__validMethod'>
													<ButtonSubmit
														title='Passer au règlement'
														styleButton='btn-default btn-default-primary'
														isActive={!isCbSelected && !isVirSelected}
														onClick={handleValidMethod}
													/>
												</div>
											)
										}

										{
											isVirSelected && (
												<ProjectStagePaymentVir
													projectInvoice={invoice}
												/>
											)
										}

									</>
								)
							}
						</section>
					</>
				)
			}
		</>
	);
}

export default ProjectStagePayment;