import React, { useEffect, useState } from 'react';
import TabNavigationStudyFolder from "./Partials/TabNavigationStudyFolder";
import StudyFolderMenu from "./Partials/StudyFolderMenu";
import PopUpInformations from "./Partials/PopUpInformations";
import PopUpSendStudyFolder from "./Partials/PopUpSendStudyFolder";
import { useParams } from "react-router-dom";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DirRespService from "../../../../../services/Ing/DirResp/DirRespService";
import Skeleton from "react-loading-skeleton";
import TitleIconNavLink from "../../../../../components/Atomes/Title/TitleIconNavLink";
import { HOME_PATH } from "../../../../../config/UrlConstants";
import { toast } from "react-toastify";
import exportFileService from "../../../../../services/ExportFileService";
import { useAuth } from "../../../../../services/useAuth";
import PopUpSendToClient from './Partials/PopUpSendToClient';
import {useNavigate} from "react-router-dom";
import PopUpDemandeDeCorrection from '../../../../../components/Molecules/PopUp/PopUpDemandeDeCorrection';
import PopUpDemandeDeCorrectionStudyFolder from '../../../../../components/Molecules/PopUp/PopUpDemandeDeCorrectionStudyFolder';
import ProjectService from '../../../../../services/ProjectService';
import PopUpExecutionClass from './Partials/PopUpExecutionClass';

const StudyFolderIng = () => {

	const [instantSave, setInstantSave] = useState(false)

	const [showInformation, setShowInformation] = useState(false)
	const [studyFolderStep] = useState("REDACTION")
	const [showPopUp, setShowPopUp] = useState(false)
	const [requestModification, setRequestModification] = useState(false)
	const [showSendToClient, setShowSendToClient] = useState(false)
	const [data, setData] = useState("")
	const [content, setContent] = useState("");
	const [loading, setLoading] = useState(true);

	const [forcedSkipped, setForcedSkipped] = useState(false)

	const [showExecutionClass, setShowExecutionClass] = useState(false)
	const [executionClass, setExecutionClass] = useState(null)
	//CKeditor
	const [thread, setThread] = useState("")
	const [users, setUsers] = useState([])
	const userIdsSet = new Set();

	const { id } = useParams()
	const { userId, role, allInformations } = useAuth();

	const navigate = useNavigate();



	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const downloadStudy = () => {
		exportFileService.exportFile(`/studies/${id}`, 'dossier-etude', {}, "pdf")
		toast.success('Téléchargement en cours');
	}

	const sendStudyFolderToVerification = async () => {
		saveNotPublish()
		let dataToSend

		if(role[0] === 'ROLE_ING_AFF') {
			dataToSend = {
				content: data,
				sendToCheck: true,
			}
		} else {
			dataToSend = {
				content: data,
				sendToClient: true
			}
		}

		const request = await DirRespService.sendStudyFolderToVerification(id, dataToSend)
		if (request.status === 200) {
			if (content.project.ingChecker?.id === userId) {
				toast.success("Le dossier d'étude a bien été envoyé au client")
				navigate(`/`)
			} else {
				toast.success("Le dossier d'étude a bien été envoyé à la vérification")
				navigate(`/`)
			}
			setShowPopUp(false)
		} else {
			toast.error("Une erreur est survenue lors de l'envoi du dossier d'étude à la vérification")
		}
	}

	const sendStudyFolderToTheClient = async () => {
		saveNotPublish()
		const request = await DirRespService.sendStudyFolderToVerification(id, { content: data, sendToClient: true })
		if (request.status === 200) {
			toast.success("Le dossier d'étude a bien été envoyé au client")
			navigate(`/`)
			setShowPopUp(false)
		} else {
			toast.error("Une erreur est survenue lors de l'envoi du dossier d'étude à la vérification")
		}
	}

	const saveNotPublish = async () => {
		const imgRegex = /<img.*?src="(.*?)".*?>/g;
		const matches = [...data.matchAll(imgRegex)];
		for (const match of matches) {
			const imgTag = match[0];
			const imgUrl = match[1];

			const response = await fetch(imgUrl);
			const blob = await response.blob();
			const base64Data = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.onloadend = () => resolve(reader.result);
				reader.readAsDataURL(blob);
			});

			const imgBase64Tag = `<img src="${base64Data}" alt="file">`;
			setData((prevData) => prevData.replace(imgTag, imgBase64Tag));
		}
	}

	const save = async () => {
		const imgRegex = /<img.*?src="(.*?)".*?>/g;
		const matches = [...data.matchAll(imgRegex)];
		for (const match of matches) {
			const imgTag = match[0];
			const imgUrl = match[1];

			const response = await fetch(imgUrl);
			const blob = await response.blob();
			const base64Data = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.onloadend = () => resolve(reader.result);
				reader.readAsDataURL(blob);
			});

			const imgBase64Tag = `<img src="${base64Data}" alt="file">`;
			setData((prevData) => prevData.replace(imgTag, imgBase64Tag));
		}
		await saveData()
	}

	const saveData = async () => {
		const request = await DirRespService.patchStudyFolderCKEDITOR(id, { content: data })
		if (request.status === 200) {
			toast.success("Le dossier d'étude a bien été sauvegardé")
		} else {
			toast.error("Une erreur est survenue lors de la sauvegarde du dossier d'étude")
		}
	}

	const getProjectsInformations = async () => {
		const request = await DirRespService.getStudyFolderInformations(id)
		if (request.status === 200) {
			setContent(request.data)
			setData(request.data.content)
			setLoading(false)
		}
	}

	useEffect(() => {
		setLoading(true)
		getProjectsInformations()
	}, [id])

	useEffect(() => {
		if(instantSave === true) {
			save()
			setInstantSave(false)
		}
	}, [instantSave])


	function userExists(user) {
		return userIdsSet.has(user.id);
	}

	function currentUserExists() {
		return userIdsSet.has(allInformations.id);
	}

	const handleDemandeDeCorrection = async () => {
		const request = await ProjectService.CorrectionRequest(id, {validation: false})
        setRequestModification(!requestModification)
        if(request.request.status === 200) {
            toast.success("La demande de correction a bien été envoyé")
			navigate("/")
        } else {
            toast.error("Une erreur est surenue")
        }
	}

	const getAllUsers = async () => {
		const request = await DirRespService.getAllThread(id);
		if (request.status === 200) {
			let users = [];
			request.data["hydra:member"].forEach((thread) => {
				thread.comments.forEach(comment => {
					const user = comment.user;
					if (!userExists(user)) {
						users.push({
							name: user.niceNames,
							id: user.id
						});
						userIdsSet.add(user.id);
					}
				});
			})

			if (!currentUserExists()) {
				users.push({
					name: `${allInformations.fn} ${allInformations.ln}`,
					id: allInformations.id
				});
			}


			users.forEach(user => {
				user.id = user.id.toString();
			});

			if (users.length > 0) {
				setUsers(users)
				return users
			}

		}
	}

	const getAllThread = async () => {
		const request = await DirRespService.getAllThread(id);
		if (request.status === 200) {
			let commentThreads = [];

			request.data["hydra:member"].forEach((thread) => {
				let mappedComments = thread.comments.map((comment) => {
					return {
						commentId: `${comment.commentId}`,
						authorId: `${comment.user.id}`,
						content: comment.content,
						createdAt: new Date(comment.createdAt),
					};
				});

				let commentThread = {
					threadId: thread.id,
					comments: mappedComments,
					context: {
						type: thread.contextType,
						value: thread.contextValue
					},
					unlinkedAt: thread.unlinkedAt ? new Date(thread.unlinkedAt) : null,
					resolvedAt: thread.resolvedAt ? new Date(thread.resolvedAt) : null,
					resolvedBy: `${thread.resolvedById}`,

				};

				commentThreads.push(commentThread);
			});

			if (commentThreads.length > 0) {
				setThread(commentThreads)
				return commentThreads
			}

		} else {
			// console.log("une erreur est survenue");
		}
	};

	class CommentsAdapter {
		constructor(editor) {
			this.editor = editor;
		}

		static get requires() {
			return ['CommentsRepository'];
		}

		init() {
			const usersPlugin = this.editor.plugins.get('Users');
			const commentsRepositoryPlugin = this.editor.plugins.get('CommentsRepository');

			getAllUsers().then((allUsers) => {
				// Load the users data.
				if (allUsers.length === undefined && users.length !== 0) {
					users.map((user) => {
						usersPlugin.addUser(user);
					})
				} else {
					allUsers.map((user) => {
						usersPlugin.addUser(user);
					})
				}
				// Set the current user.
				usersPlugin.defineMe(`${allInformations.id}`);
			})

			// Set the adapter on the `CommentsRepository#adapter` property.
			commentsRepositoryPlugin.adapter = {
				addComment(data) {

					const dataToSend = {
						'content': data.content,
						'threadId': data.threadId,
						'commentId': data.commentId
					}

					DirRespService.addNewThread(id, dataToSend).then((response) => {
						if (response.status === 201) {
							toast.success('Le commentaire a été ajouté');
						} else {
							toast.error("Le commentaire n'a pas été ajouté")
						}
					})

					return Promise.resolve({
						createdAt: new Date()
					});
				},

				updateComment(data) {
					// console.log('Comment updated', data);

					const dataToSend = {
						threadId: data.threadId,
						content: data.content
					}

					DirRespService.editComment(data.commentId, dataToSend).then((response) => {
						if (response.status === 200) {
							toast.success("Le commentaire a été modifié")
						} else {
							toast.error("Une erreur est survenue durant la suppression du commentaire")
						}
					})

					// Write a request to your database here. The returned `Promise`
					// should be resolved when the request has finished.
					return Promise.resolve();
				},

				removeComment(data) {
					// console.log('Comment removed', data);

					DirRespService.removeComment(data.commentId).then((response) => {
						if (response.status === 204) {
							toast.success("Le commentaire a été supprimé")
						} else {
							toast.error("Une erreur est survenue durant la suppression du commentaire")
						}
					})


					// Write a request to your database here. The returned `Promise`
					// should be resolved when the request has finished.
					return Promise.resolve();
				},

				addCommentThread(data) {
					// console.log('Comment thread added', data);

					const dataToSend = {
						'content': data.comments[0].content,
						'commentId': data.comments[0].commentId,
						'threadId': data.threadId,
						'contextType': data.context.type,
						'contextValue': data.context.value,
					}

					DirRespService.addNewThread(id, dataToSend).then((response) => {
						if (response.status === 201) {
							toast.success('Le commentaire a été ajouté');
							setInstantSave(true)
						} else {
							toast.error("Une erreur est survenue")
						}
					})

					return Promise.resolve({
						threadId: data.threadId,
						comments: data.comments.map((comment) => ({ commentId: comment.commentId, createdAt: new Date() })) // Should be set on the server side.
					});
				},

				getCommentThread(data) {
					// console.log('Getting comment thread', data);

					return Promise.resolve(
						getAllThread()
							.then((thread) => {
								const foundThread = thread?.find((oneThread) => oneThread.threadId === data.threadId);
								if (foundThread) {
									return foundThread;
								}
							})
					);
				},

				updateCommentThread(data) {
					// console.log('Comment thread updated', data);

					// Write a request to your database here. The returned `Promise`
					// should be resolved when the request has finished.
					return Promise.resolve();
				},

				resolveCommentThread(data) {
					// console.log('Comment thread resolved', data);

					const dataToSend = {
						'resolvedAt': new Date(),
						'resolvedById': allInformations.id,
						'unlinkedAt': null,
					}

					DirRespService.manageThread(id, data.threadId, dataToSend).then((response) => {
						if(response.status === 200) {
							toast.success("Le thread a été résolu")
							setInstantSave(true)
						} else {
							toast.error("Une erreur est survenue")
						}
					})

					// Write a request to your database here. The returned `Promise`
					// should be resolved when the request has finished.
					return Promise.resolve({
						resolvedAt: new Date(), // Should be set on the server side.
						resolvedBy: usersPlugin.me.id // Should be set on the server side.
					});
				},

				reopenCommentThread(data) {
					// console.log('Comment thread reopened', data);
					const dataToSend = {
						'resolvedAt': null,
						'resolvedBy': null,
					}

					DirRespService.manageThread(id, data.threadId, dataToSend).then((response) => {
						if(response.status === 200) {
							toast.success("Le thread a été réouvert")
							setInstantSave(true)
						} else {
							toast.error("Une erreur est survenue")
						}
					})

					// Write a request to your database here. The returned `Promise`
					// should be resolved when the request has finished.
					return Promise.resolve();
				},

				removeCommentThread(data) {
					// console.log('Comment thread removed', data);

					DirRespService.removeThread(id, data.threadId).then((response) => {
						if (response.status === 204) {
							toast.success("Le thread a bien été supprimé")
							setInstantSave(true)
						} else {
							toast.error("Une erreur est survenue")
						}
					})
					return Promise.resolve();
				}
			};
		}
	}

	const getExecutionClass = async () => {
        const request = await DirRespService.getExecutionClass(id)
        if(request.status === 200) {
            setExecutionClass(request.data)
        }
    }


	const CheckSendToVerif = async () => {
		if(role[0] === 'ROLE_ING_AFF' && content.needExecutionClass === true && executionClass.skipped === false && executionClass.choices.length === 0 && forcedSkipped === false) {
			setShowExecutionClass(true)
		} else {
			setShowPopUp(!showPopUp)
		}
	}

	const CheckSendToClient = async () => {
		if(role[0] === 'ROLE_ING_AFF' && content.needExecutionClass === true && executionClass.skipped === false && executionClass.choices.length === 0 && forcedSkipped === false) {
			setShowExecutionClass(true)
		} else {
			setShowSendToClient(!showSendToClient)
		}
	}

	const skippeExecutionClass = async () => {
		const request = await DirRespService.patchExecutionClass(id, {
			skipped: true
		})

		if(request.status === 200) {
			toast.success("La classe d'execution a bien été ignoré")
			setForcedSkipped(true)
			setShowExecutionClass(false)
		}
	}

	useEffect(() => {
		getExecutionClass()
	}, [id])

	return (
		<>
			{
				loading ? <Skeleton height={500} /> : (
					<>
						<section className={"section-study-folder-introduction mbe-section"}>
							<div className={"section-study-folder-introduction-title"}>
								<TitleIconNavLink
									title={`${content.project.client.niceNames} - ${content.project.address.street}, ${content.project.address.nicePostcode}, ${content.project.address.city}`}
									link={HOME_PATH}
									information={true}
									onClick={() => setShowInformation(true)} />
							</div>
							<div className={"align-items justify-content-space-between align-items-center"}>
								<TabNavigationStudyFolder theStep={studyFolderStep} id={id} needExecutionClass={content.needExecutionClass} needCCTP={content.needCctp}
									needSummary={content.needSummary} />
								<div className={"align-items"}>
									<button
										title={"Télécharger dossier d’étude structure"}
										onClick={() => downloadStudy()}
										className='btn-default btn-purple-secondary'
									>
										Télécharger le dossier d’étude structure
									</button>



									<button
										title={role[0] === 'ROLE_ING_AFF' ? "Envoyer en vérification" : "Valider et envoyer au client"}
										onClick={() => CheckSendToVerif()}
										className='btn-default btn-purple'
									>
										{role[0] === 'ROLE_ING_AFF' ? "Envoyer en vérification" : "Valider et envoyer au client"}
									</button>


									{
										role[0] === 'ROLE_ING_AFF' ? (
											<button
												title={"Valider et envoyer au client"}
												onClick={() => CheckSendToClient()}
												className={"btn-default btn-purple"}
											>
												Valider et envoyer au client
											</button>
										) : null
									}


									{
										role[0] === "ROLE_ING_VERIF" ? (
											<button
												title={"Demande de correction"}
												onClick={() => setRequestModification(!requestModification)}
												className={"btn-default btn-purple"}
											>
												Demande de correction
											</button>
										) : null 
									}

								</div>

							</div>
							<div className={"section-study-folder-introduction-container"}>


								<div className={"section-study-folder-introduction-container-editor"}>
									<div className={"section-study-folder-introduction-container-editor-title"}>
										<h3 className={"text-primary color-primary"}>Rédaction du dossier d'étude</h3>
										<img alt={"img save"} src={"/images/pictos/Save.svg"} onClick={() => save()} />
									</div>
									<div id='container'>
									<CKEditor
										config={{
											sidebar: {
												container: null
											},
											extraPlugins: [CommentsAdapter],
											licenseKey: 'ZEFhU2ZQSlo4TkFHSE9SMXcrcDU5dnhldzVRVjMvdEE2UjZsRTIrR1ZXK3g5cjhUWCtaZHNUbS9UeUpaLU1qQXlOREEwTVRrPQ=='
										}}
										editor={Editor}
										initialData={content.content}
										data={content.content}
										onChange={(event, editor) => {
											setData(editor.getData());
										}}
									/>
									</div>
									
								</div>

								<StudyFolderMenu content={content} projectId={content.project.id} />
							</div>

							<PopUpInformations
								content={content}
								show={showInformation}
								onClick={() => setShowInformation(false)}
								large={false}
								title={"Informations projet"}
							/>

							{
								showPopUp ? (
									<PopUpSendStudyFolder id={id} onClick={() => setShowPopUp(false)}
										approve={() => sendStudyFolderToVerification()}
										projectData={content.project}
									/>
								) : null
							}

							{
								showExecutionClass ? (
									<PopUpExecutionClass id={id} onClick={() => setShowExecutionClass(false)} approve={() => skippeExecutionClass()} />
								) : null
							}

							{
								showSendToClient ? (
									<PopUpSendToClient id={id} onClick={() => setShowSendToClient(false)} approve={() => sendStudyFolderToTheClient()} />
								) : null
							}

							{
								requestModification ? (
									<PopUpDemandeDeCorrectionStudyFolder idProject={id} onClickNo={() => setRequestModification(!requestModification)} onClickYes={() => handleDemandeDeCorrection()} />
								) : null 
							}


						</section>
					</>
				)
			}
		</>

	);
};

export default StudyFolderIng;
