import React, { createContext, useEffect, useState } from "react";
import TableListingHeading from "../../Coms/Dir/Collaborators/Partials/CollaboratorsHeadingListingTable";
import { SortingTable } from "../../../../components/Molecules/Tables/SortingTable";
import { USERS_COLUMNS } from "../../../../config/ArrayColumns/Admin/UsersColumns";
import PopUpForm from "../../../../components/Molecules/PopUp/PopUpForm";
import FormAddUser from "../../../../components/Molecules/Form/FormAddUser";
import { toast } from "react-toastify";
import { ConvertObjectToUrlParams } from "../../../../utils/StringUtils";
import UserService from "../../../../services/UserService";
import Skeleton from "react-loading-skeleton";
import { userRoleTranslate } from "../../../../utils/UserRolesUtils";
import InputSearch from "../../../../components/Atomes/Inputs/InputSearch";


export const StateRequest = createContext()
const UsersAdmin = () => {

	const [stateRequest, setStateRequest] = useState("");
	const [search, setSearch] = useState("");
	const [showPopUp, setShowPopUp] = useState(false);
	const [loading, setLoading] = useState(true);

	const DisplayToast = (type) => {
		if (type === "success add") {
			toast.success("L'utilisateur a bien été ajouté")
		} else if (type === "success edit") {
			toast.success("L'utilisateur a bien été modifié")
		} else if (type === "success delete") {
			toast.success("L'utilisateur a bien été supprimé")
		} else if (type === "error") {
			toast.error("Une erreur est survenue")
		}
		setStateRequest("")
	}

	const DisplayCreateModalCollaborator = () => {
		setShowPopUp(!showPopUp)
	}
	const [usersCollection, setUsersCollection] = useState([]);

	const requestGetAllUsers = async () => {
		const URL = ConvertObjectToUrlParams("users", { roles: "ROLE_USER", pagination: "true", search: search })
		const request = await UserService.getAllUsers(URL)
		if (request.request.status === 200) {
			setUsersCollection(request.data)
			setLoading(false)
		} else {
			toast.error('Une erreur est servenue lors de la récupération de la liste des utilisateurs')
		}
	}

	useEffect(() => {
		setLoading(true)
		requestGetAllUsers()
	}, [search]);


	useEffect(() => {
		if (stateRequest !== "") {
			setUsersCollection([])
			window.scrollTo(0, 0);
			DisplayToast(stateRequest)
		}
		requestGetAllUsers()
	}, [stateRequest, search]);

	return (
		<StateRequest.Provider value={{ stateRequest, setStateRequest }}>
			<InputSearch placeholder={"Entrer la recherche puis valider"}
				onChangeSearchValue={(event) => event.target.value.length === 0 ? setSearch("") : null}
				name={"Recherche"}
				onChangeSearch={(event) => (event.key === "Enter" || event.target.value.length === 0) ? setSearch(event.target.value.toLowerCase()) : null} />
			<TableListingHeading search={search} title={"Utilisateurs"} onClick={(e) => setShowPopUp(true)}
				titleButton={"Ajouter un utilisateur"} isAdmin={true} />
			{loading ?
				<div>
					<Skeleton count={1} height={1000} />
				</div> :
				<div className={"section-users-admin-container"}>
					<SortingTable columnsTable={USERS_COLUMNS} dataTable={usersCollection} />
				</div>
			}
			<PopUpForm large={true} title={"Ajout un utilisateur"} form={<FormAddUser setShowPopUp={setShowPopUp} />}
				onClick={DisplayCreateModalCollaborator} show={showPopUp} />
		</StateRequest.Provider>
	)
}

export default UsersAdmin
