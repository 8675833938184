import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { toast } from "react-toastify";

import PopupValidTask from "./Popup/PopupValidTaks";
import { useAuth } from "../../../../services/useAuth";

const CardTasksInProgress = ({ project, taskValidated }) => {
  const navigate = useNavigate();
  const [taskValidatedId, setTaskValidatedId] = useState(null);
  const [taskValidatedType, setTaskValidatedType] = useState(null);
  const { role } = useAuth();

  const rolesProject = project.user.roles;
  const arrayRoleProject = [];
  rolesProject.includes("ROLE_ING_AFF") ? arrayRoleProject.push("AFF") : null;
  rolesProject.includes("ROLE_ING_DIAG") ? arrayRoleProject.push("DIAG") : null;
  rolesProject.includes("ROLE_ING_VERIF")
    ? arrayRoleProject.push("VERIF")
    : null;
  rolesProject.includes("ROLE_ING_EXTRA")
    ? arrayRoleProject.push("EXTRA")
    : null;

  const rolesProjectString = arrayRoleProject.join(", ");

  //afficher l'icone + navigate
  let iconTask = "";
  let urlNavigate = "";
  switch (project.type) {
    case "task-project-ing-study":
    case "task-project-ing-study-extra":
    case "task-project-ing-study-check":
    case "task-project-ing-study-check-extra":
    case "task-project-ing-study-fix":
    case "task-project-ing-study-fix-extra":
      iconTask = "/images/pictos/tasks/task-visite-compte-rendu.svg";
      urlNavigate = "redaction-du-dossier-etude";
      break;

    case "task-project-ing-pri":
    case "task-project-ing-pri-extra":
      iconTask = "/images/pictos/tasks/task-dossier-etude.svg";
      urlNavigate = "pri";
      break;

    case "task-project-ing-visit":
    case "task-project-ing-visit-extra":
      iconTask = "/images/pictos/tasks/task-visite-compte-rendu.svg";
      urlNavigate = "";
    default:
      iconTask = "/images/pictos/tasks/task-visite-compte-rendu.svg";
      urlNavigate = "";
  }

  const [showPopupValidTask, setShowPopupValidTask] = useState(false);
  const openPopupValidTask = (e) => {
    setShowPopupValidTask(!showPopupValidTask);
    setTaskValidatedType(project.type);
    setTaskValidatedId(project.id);
  };
  const closeAction = () => {
    setShowPopupValidTask(false);
    setTaskValidatedType(null);
    setTaskValidatedId(null);
  };
  //calculer l'avancement
  const startDate = moment(project.startAt)._d.getTime();
  const today = new Date().getTime();
  const endDate = moment(project.endAt)._d.getTime();
  const progressTime = ((today - startDate) / (endDate - startDate)) * 100;
  let percentAchievement = Math.round(progressTime);
  if (percentAchievement < 0) {
    percentAchievement = 0;
  }

  return (
    <>
      <article
        className="card-task"
        onClick={() => {
          if (project.status === "pending") {
            toast.info("La tâche n'est pas disponible");
          } else if (project.status === "processing") {
            if (urlNavigate !== "") {
              navigate(`/dossier-etude/${project.studyId}/${urlNavigate}`);
            } else {
              toast.info("La tâche n'est pas disponible");
            }
          } else {
            openPopupValidTask();
          }
        }}
      >
        {role[0] === "ROLE_ING_DIAG" && project.niceType === "Visite et réalisation du compte rendu" ? (
          <div className="card-task-bloc">
            <div className="card-task-intro">
              {/*<img src={`${project.icon_task}`} />*/}
              <img src={iconTask} />
              <div className="card-task-intro-text">
                <h3 className="color-primary">{project.niceType}</h3>
                <p className="text-primary color-primary">
                  {`${project.validQuoteServiceReferences.join(", ")}`}
                </p>
                {/* <p className="text-primary color-primary">
                  {project.project.type}{" "}
                  {rolesProjectString ? "- " + rolesProjectString : null}
                </p> */}
              </div>
            </div>

            <div className="card-task-content">
              <div className="card-task-content-line">
                <p className="text-primary color-primary">Client</p>
                <p className="text-primary color-primary paragraph_bold text_align_right">
                  {" "}
                  {`${project.project.client.niceGender} ${project.project.client.firstname} ${project.project.client.lastname}`}
                </p>
              </div>
              <div className="card-task-content-line">
                <p className="text-primary color-primary">Numéro client</p>
                <p className="text-primary color-primary paragraph_bold text_align_right">
                  {project.project.client.reference
                    ? project.project.client.reference
                    : "N/A"}
                </p>
              </div>

              <div className="card-task-content-line">
                <p className="text-primary color-primary">Adresse du projet</p>
                <p className="text-primary color-primary paragraph_bold text_align_right">
                  {project.visit.address.street}, {project.visit.address.city}
                </p>
              </div>

              <div className="card-task-content-line">
                <p className="text-primary color-primary">Numéro de contact</p>
                <p className="text-primary color-primary paragraph_bold text_align_right">
                  {project.visit.contactPhone
                    ? project.visit.contactPhone
                    : project.visit.client.phone}
                </p>
              </div>

              <div className="card-task-content-line">
                <p className="text-primary color-primary">Architecte</p>
                <p className="text-primary color-primary paragraph_bold text_align_right">
                  {project.project.arch
                    ? project.project.arch.niceGender +
                      " " +
                      project.project.arch.niceNames
                    : "N/A"}
                </p>
              </div>

              <div className="card-task-content-line">
                <p className="text-primary color-primary">Date de la visite</p>
                <p className="text-primary color-primary paragraph_bold text_align_right">
                  {project.visit.startAt
                    ? moment(project.visit.startAt).format("DD/MM/YYYY")
                    : null}
                </p>
              </div>

              <div className="card-task-content-line">
                <p className="text-primary color-primary">Créneaux de visite</p>
                <p className="text-primary color-primary paragraph_bold text_align_right">
                  {project.visit.niceDuration}
                </p>
              </div>

              <div className="card-task-content-line">
                <p className="text-primary color-primary">Temps prévisionnel</p>
                <p className="text-primary color-primary paragraph_bold text_align_right">
                  {moment
                    .utc(
                      moment
                        .duration(project.estimatedDuration, "h")
                        .asMilliseconds()
                    )
                    .format("HH[h]mm")}
                </p>
              </div>

              <div className="card-task-content-line">
                <p className="text-primary color-primary">
                  Informations complémentaire
                </p>
                <p className="text-primary color-primary paragraph_bold text_align_right">
                  {project.visit.contactMore
                    ? project.visit.contactMore
                    : "N/A"}
                </p>
              </div>
            </div>

            <div className="card-task-improvement">
              <div className="card-task-improvement-percent">
                <div className="card-task-improvement-percent-bar">
                  <div style={{ width: `${percentAchievement}` + "%" }}></div>
                </div>
                <p className="text-primary color-blue-jeans">{`${percentAchievement}%`}</p>
              </div>

              <div
                className={`card-task-improvement-status ${
                  project.status === "pending"
                    ? "red"
                    : project.status === "valid"
                    ? "vert"
                    : "orange"
                }`}
              >
                <p className="text-primary color-dark-grey">
                  {project.niceStatus}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="card-task-bloc">
            <div className="card-task-intro">
              {/*<img src={`${project.icon_task}`} />*/}
              <img src={iconTask} />
              <div className="card-task-intro-text">
                <h3 className="color-primary">{project.niceType}</h3>
                <p className="text-primary color-primary">
                  {`${project.validQuoteServiceReferences.join(", ")}`}
                </p>
                {/* <p className="text-primary color-primary">
                  {project.project.type}{" "}
                  {rolesProjectString ? "- " + rolesProjectString : null}
                </p> */}
              </div>
            </div>

            <div className="card-task-content">
              <div className="card-task-content-line">
                <p className="text-primary color-primary">Client</p>
                <p className="text-primary color-primary paragraph_bold text_align_right">{`${project.project.client.firstname} ${project.project.client.lastname}`}</p>
              </div>
              <div className="card-task-content-line">
                <p className="text-primary color-primary">Numéro client</p>
                <p className="text-primary color-primary paragraph_bold text_align_right">
                  {project.project.client.reference}
                </p>
              </div>
              <div className="card-task-content-line">
                <p className="text-primary color-primary">
                  Ingénieur vérificateur
                </p>
                <p className="text-primary color-primary paragraph_bold text_align_right">
                  {project.project.ingChecker
                    ? `${project.project.ingChecker?.niceGender} ${project.project.ingChecker?.niceNames}`
                    : "N/A"}
                </p>
              </div>
              <div className="card-task-content-line">
                <p className="text-primary color-primary">Date de la visite</p>
                <p className="text-primary color-primary paragraph_bold text_align_right">
                  {project.visit?.startAt
                    ? moment(project.visit.startAt).format("DD/MM/YYYY")
                    : "Pas de visite"}
                </p>
              </div>
              <div className="card-task-content-line">
                <p className="text-primary color-primary">
                  Date du principe de structure
                </p>
                <p className="text-primary color-primary paragraph_bold text_align_right">
                  {project.project.startAt
                    ? moment(project.project.startAt).format("DD/MM/YYYY")
                    : "Pas de PRI"}
                </p>
              </div>
              <div className="card-task-content-line">
                <p className="text-primary color-primary">Date de rendu</p>
                <p className="text-primary color-primary paragraph_bold text_align_right">
                  {moment(project.endAt).format("DD/MM/YYYY")}
                </p>
              </div>

              <div className="card-task-content-line">
                <p className="text-primary color-primary">Temps prévisionnel</p>
                <p className="text-primary color-primary paragraph_bold text_align_right">
                  {moment
                    .utc(
                      moment
                        .duration(project.estimatedDuration, "h")
                        .asMilliseconds()
                    )
                    .format("HH[h]mm")}
                </p>
              </div>
            </div>

            <div className="card-task-improvement">
              <div className="card-task-improvement-percent">
                <div className="card-task-improvement-percent-bar">
                  <div style={{ width: `${percentAchievement}` + "%" }}></div>
                </div>
                <p className="text-primary color-blue-jeans">{`${percentAchievement}%`}</p>
              </div>

              <div
                className={`card-task-improvement-status ${
                  project.status === "pending"
                    ? "red"
                    : project.status === "valid"
                    ? "vert"
                    : "orange"
                }`}
              >
                <p className="text-primary color-dark-grey">
                  {project.niceStatus}
                </p>
              </div>
            </div>
          </div>
        )}

        {/*<div className="card-task-comment">
                {task.commentaires?.map((commentaire, index) => {
                    return(
                        <div key={index} className="card-task-comment-item">
                        <p className="text-primary paragraph_bold color-primary card-task-comment-item-name">{commentaire.name}</p>
                        <p className="text-primary color-primary text-micro paragraph_bold card-task-comment-item-date">{`${commentaire.date} à ${commentaire.hour}`}</p>
                        <p className="text-primary color-primary card-task-comment-item-content">{commentaire.content}</p>
                        </div>
                        )
                    })}
                </div>*/}
      </article>
      {showPopupValidTask && (
        <PopupValidTask
          showPopup={showPopupValidTask}
          closeAction={closeAction}
          taskValidatedType={taskValidatedType}
          taskValidatedId={taskValidatedId}
          taskValidated={taskValidated}
        />
      )}
    </>
  );
};

export default CardTasksInProgress;
