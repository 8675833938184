import React, {useEffect} from 'react';
import TitleSection from "../../../../../../../components/Atomes/Title/TitleSection";
import InputLabelErrorMessage from "../../../../../../../components/Atomes/Formik/InputLabelErrorMessage";
import {useFormikContext} from "formik";

const FormDevisMeetState = ({project}) => {
	const {setFieldValue} = useFormikContext()

	/*Initial Values*/
	useEffect(() => {
		if (project.visitType) {
			setFieldValue('visit', project.visitType)
		}
	}, [project]);

	const dataSelect = [
		{
			label: "Visite d'une heure",
			value: "t1"
		},
		{
			label: "Visite de deux heures",
			value: "t2"
		},
		{
			label: "Pas de visite",
			value: ""
		},
		{
			label: "Visite à prendre avec les ingénieurs",
			value: null
		},
	]

	return (
		<div className={"form-devis-container-state-meet"}>
			<div className={"form-section-title"}>
				<TitleSection title={"Type de visite"}/>
			</div>
			<div className={"form-devis-container-information-agence-start"}>
				<div className={"form-row-element form-row-single-elements"}>
					<InputLabelErrorMessage keyLabel={"label"} keyValue={"value"} type={"select"}
					                        label={"Choisir le type de visite"} name={"visit"} data={dataSelect}/>
				</div>
			</div>
		</div>
	);
};

export default FormDevisMeetState;
