import React, {useContext} from "react";
import DirRespService from "../../../services/Ing/DirResp/DirRespService";
import {StateRequest} from "../../../views/Pages/Ings/Dir/Controle/ControleViewIngDir";

const PopUpControlEngineer = ({id, onClick}) => {
	const {setStateRequest} = useContext(StateRequest)

	const handleSubmit = async () => {
		const request = await DirRespService.archiveProject(id, {validation: true})
		onClick();
		if (request.request.status === 200) {
			setStateRequest("patch")
		} else {
			setStateRequest("error")
		}
	}

	return (
		<div className={"pop-up-base-style active"}>
			<div className={"pop-up-user-delete"}>
				<img onClick={onClick} className={"cross-close"} src={"/images/pictos/cross-close.svg"} alt={"cross close"}/>
				<img src={"/images/medias/popup.svg"} alt="img form"/>
				<h4>Êtes-vous sur de vouloir valider le contrôle de l'étude et permettre à l'ingénieur chargé d'affaire
					d'envoyer l'étude au client ?</h4>
				<div className={"pop-up-user-delete-buttons"}>
					<button className={"btn btn-cancel"} onClick={() => onClick()}>Non</button>
					<button className={"btn btn-delete"} onClick={(event) => handleSubmit()}>Oui</button>
				</div>
			</div>
		</div>
	);
}

export default PopUpControlEngineer
