import React from "react";
import RolesList from "./RolesList";
import * as URL from "./UrlConstants";

import Chatbot from "../views/Chatbot/Chatbot";
import Profile from "../views/User/Profile/Profile";

// Coms
import CustomerViewComs from "../views/Pages/Coms/All/CustomerView";
import ProjectMediasComs from "../views/Pages/Coms/All/ProjectView";
// Dir
import Home from "../views/Pages/Coms/Dir/Home/Home";
import Objectives from "../views/Pages/Coms/Dir/Objectives/Objectives";
import ApprovalCircuitView from "../views/Pages/Coms/Dir/ApprovalCircuit/ApprovalCircuitView";
import KPIView from "../views/Pages/Coms/Dir/KPI/KPIView";
import Collaborators from "../views/Pages/Coms/Dir/Collaborators/Collaborators";
import PrestationsComsDir from "../views/Pages/Coms/Dir/Prestations/PrestationsComsDir";

// Res
import ManagerHome from "../views/Pages/Coms/Res/Home/ManagerHome";
import NewRequest from "../views/Pages/Coms/Res/NewRequest/NewRequest";
import ManagerKPIView from "../views/Pages/Coms/Res/Kpis/ManagerKPIView";
import ResCalendarView from "../views/Pages/Coms/Res/Calendar/View";
import PrestationsComsRes from "../views/Pages/Coms/Res/Prestations/PrestationsComRes";

// Com
import HomeCommercial from "../views/Pages/Coms/Com/Home/Home";
import ComKpisView from "../views/Pages/Coms/Com/Kpis/Kpi";
import PastStudiesView from "../views/Pages/Coms/Com/PastStudies/PastStudiesView";
import HomeAdmin from "../views/Pages/Admin/Home/Home";
import ComCalendarView from "../views/Pages/Coms/Com/Calendar/View";
import Devis from "../views/Pages/Coms/Com/Devis/Devis";
import ArchiveCom from "../views/Pages/Coms/Com/Archive/Archive.js";

// Admin
import KpiAdmin from "../views/Pages/Admin/KPI/KpiAdmin";
import ProjectsAdmin from "../views/Pages/Admin/Projects/ProjectsAdmin";
import UsersAdmin from "../views/Pages/Admin/Users/UsersAdmin";
import AdminCalendar from "../views/Pages/Admin/Calendar/AdminCalendar";
import AdminDpgfView from "../views/Pages/Admin/Dpgf/View";
import AdminCctpView from "../views/Pages/Admin/Cctp/View";
import AdminBills from "../views/Pages/Admin/Bill/AdminBills";
import Partners from "../views/Pages/Admin/Partners/Partners";
import PartnersStats from "../views/Pages/Admin/Partners/PartnersStats";
import ChatbotAdmin from "../views/Pages/Admin/Chatbot/ChatbotAdmin";
import CustomerViewAdmin from "../views/Pages/Admin/CustomerView/CustomerView";
import DocumentViewAdmin from "../views/Pages/Admin/DocumentView/DocumentView";

// Architect
import HomeArchitect from "../views/Pages/Arch/Home/HomeArchitect";
import CustomerListingArch from "../views/Pages/Arch/Customers/CustomerListingView.js";
import CustomerDetailArch from "../views/Pages/Arch/Customers/CustomerDetailView";
import ProfileArchitect from "../views/Pages/Arch/Profile/ProfileArchitect";

// Customer
import HomeCustomer from "../views/Pages/Customer/Home/HomeCustomer";
import MarketplaceCustomer from "../views/Pages/Customer/Marketplace/MarketplaceCustomer";
import ProfileCustomer from "../views/Pages/Customer/Profile/ProfileCustomer";
import MarketPlace from "../views/Pages/Admin/MarketPlace/MarketPlace";

// Project
import ProjectStageManager from "../views/ProjectStages/ProjectStageManager";
import ProjectMedias from "../views/ProjectStages/Medias/ProjectMedias";
import ProjectStageStart from "../views/ProjectStages/Stages/Start/ProjectStageStart";
import ProjectStageSignature from "../views/ProjectStages/Stages/Signature/ProjectStageSignature";
import ProjectStageDeposit from "../views/ProjectStages/Stages/Deposit/ProjectStageDeposit";
import ProjectStageVisite from "../views/ProjectStages/Stages/Visite/ProjectStageVisite";
import ProjectStageVisiteMarketplace from "../views/ProjectStages/Stages/Visite/ProjectStageVisiteMarketplace";
import ProjectStagePrincipeStructure
	from "../views/ProjectStages/Stages/PrincipeStructure/ProjectStagePrincipeStructure";
import ProjectStageStudy from "../views/ProjectStages/Stages/Study/ProjectStageStudy";
import ProjectStagePayment from "../views/ProjectStages/Stages/Payment/ProjectStagePayment";
import ProjectStageStudyValidation from "../views/ProjectStages/Stages/StudyValidation/ProjectStageStudyValidation";
import ProjectStageRefund from "../views/ProjectStages/Stages/Refund/ProjectStageRefund";
import ProjectStageEnd from "../views/ProjectStages/Stages/End/ProjectStageEnd";


// Ings
import CustomerViewIng from "../views/Pages/Ings/All/CustomerView";

//Ing Dir
import HomeIngDir from "../views/Pages/Ings/Dir/Home/HomeIngDir";
import KpiViewIngDir from "../views/Pages/Ings/Dir/Kpi/KpiViewIngDir";
import CollaboratorsViewIngDir from "../views/Pages/Ings/Dir/Collaborators/CollaboratorsViewIngDir";
import ObjectifsViewIngDir from "../views/Pages/Ings/Dir/Objectifs/ObjectifsViewIngDir";
import ControleViewIngDir from "../views/Pages/Ings/Dir/Controle/ControleViewIngDir";

//Ing Res
import HomeResponsableIng from "../views/Pages/Ings/Res/Home/HomeResponsableIng";

//Ing Man
import HomeIngManager from "../views/Pages/Ings/Man/Home/HomeIngManager";

//Ing prod
import HomeIngProd from "../views/Pages/Ings/Prod/Home/HomeIngProd";
import HomeIngProdTaskDetail from "../views/Pages/Ings/Prod/Home/TaskDetail/HomeIngProdTaskDetail";
import ProjectsListIng from "../views/Pages/Ings/Man/Projects/ProjectsListIng";
import CalendarIngManager from "../views/Pages/Ings/Man/Calendar/CalendarIngManager";
import ApprovalCircuitIngDir from "../views/Pages/Ings/Dir/ApprovalCircuit/ApprovalCircuitIngDir";
import StudyFolderIng from "../views/Pages/Ings/Man/StudyFolder/StudyFolder";
import StudyFolderProjetSheet from "../views/Pages/Ings/Man/StudyFolder/Steps/ProjectSheet/StudyFolderProjetSheet";
import StudyFolderCCTP from "../views/Pages/Ings/Man/StudyFolder/Steps/CCTP/StudyFolderCCTP";
import StudyFolderDPGF from "../views/Pages/Ings/Man/StudyFolder/Steps/DPGF/StudyFolderDPGF";
import StudyFolderTSR from "../views/Pages/Ings/Man/StudyFolder/Steps/TSR1/StudyFolderTSR";
import StudyFolderPRI from "../views/Pages/Ings/Man/StudyFolder/Steps/PRI/StudyFolderPRI";
import ProfileING from "../views/Pages/Ings/Profil/ProfileING";
import CustomerListingViewIng from "../views/Pages/Ings/Man/Home/Teams/partials/CustomerListingViewIng";
import StudyFolderTSR2 from "../views/Pages/Ings/Man/StudyFolder/Steps/TSR2/StudyFolderTSR2";
import StudyFolderAnnexe from "../views/Pages/Ings/Man/StudyFolder/Steps/Annexe/StudyFolderAnnexe";
import DevisRefonte from "../views/Pages/Coms/Com/Devis/DevisRefonte.js";
import HomeIngDiag from "../views/Pages/Ings/Diag/HomeIngDiag.js";
import StudyFolderExecutionClass from "../views/Pages/Ings/Man/StudyFolder/Steps/ExecutionClass/StudyFolderExecutionClass.js";


export default [
	{
		component: <Chatbot/>,
		path: URL.CHATBOT_VIEW_PATH,
		title: "Chatbot",
		permission: [
			RolesList.ADMIN,
			RolesList.ARCHITECTE,
			RolesList.CUSTOMER,
			RolesList.COMMERCIAL,
			RolesList.DIRECTEUR_COMMERCIAL,
			RolesList.RESPONSABLE_COMMERCIAL,
			RolesList.MANAGER_INGENIEUR,
			RolesList.AFFAIRES_INGENIEUR,
			RolesList.DIAGNOSTIQUEUR_INGENIEUR,
			RolesList.VERIFICATEUR_INGENIEUR,
		]
	},
	// COMS
	// Common
	{
		component: <CustomerViewComs/>,
		path: URL.COMS_CUSTOMER_VIEW,
		title: "Customer view",
		titleHelloUser: false,
		permission: [
			RolesList.COMMERCIAL,
			RolesList.DIRECTEUR_COMMERCIAL,
			RolesList.RESPONSABLE_COMMERCIAL,
		],
	},
	{
		component: <ProjectMediasComs/>,
		path: URL.COMS_PROJECT_VIEW,
		title: "Project view",
		titleHelloUser: false,
		permission: [
			RolesList.COMMERCIAL,
			RolesList.DIRECTEUR_COMMERCIAL,
			RolesList.RESPONSABLE_COMMERCIAL,
			RolesList.MANAGER_INGENIEUR,
			RolesList.AFFAIRES_INGENIEUR,
			RolesList.DIAGNOSTIQUEUR_INGENIEUR,
			RolesList.VERIFICATEUR_INGENIEUR,
			RolesList.ASSISTANT_INGENIEUR,
			RolesList.DIRECTEUR_INGENIEUR,
			RolesList.RESPONSABLE_INGENIEUR,
		],
	},
	// Dir
	{
		component: <Home/>,
		path: URL.HOME_PATH,
		title: "Home director",
		titleHelloUser: true,
		permission: [
			RolesList.DIRECTEUR_COMMERCIAL
		],
	},
	{
		component: <ApprovalCircuitView/>,
		path: URL.COMS_DIR_VALIDATION_CIRCUIT,
		title: "Circuit validation director",
		titleHelloUser: true,
		permission: [
			RolesList.DIRECTEUR_COMMERCIAL
		],
	},
	{
		component: <KPIView/>,
		path: URL.KPI_PATH,
		titleHelloUser: true,
		title: "KPI",
		permission: [
			RolesList.DIRECTEUR_COMMERCIAL
		]
	},
	{
		component: <Collaborators/>,
		path: URL.COMS_DIR_COLLABORATORS,
		titleHelloUser: true,
		title: "Collaborateurs",
		permission: [
			RolesList.DIRECTEUR_COMMERCIAL
		]
	},
	{
		component: <Objectives/>,
		path: URL.COMS_DIR_OBJECTIVES_VIEW,
		titleHelloUser: true,
		title: "Objectifs",
		permission: [
			RolesList.DIRECTEUR_COMMERCIAL
		]
	},
	{
		component: <PrestationsComsDir/>,
		path: URL.PRESTATIONS_PATH,
		titleHelloUser: true,
		title: "Prestations",
		permission: [
			RolesList.DIRECTEUR_COMMERCIAL
		]
	},
	{
		component: <Profile/>,
		path: URL.PROFILE_PATH,
		title: "Profile",
		permission: [
			RolesList.DIRECTEUR_COMMERCIAL
		]
	},
	// Res
	{
		component: <ManagerHome/>,
		path: URL.HOME_PATH,
		title: "Home manager",
		titleHelloUser: true,
		permission: [
			RolesList.RESPONSABLE_COMMERCIAL
		]
	},
	{
		component: <NewRequest/>,
		path: URL.COMS_RES_NEW_REQUESTS,
		titleHelloUser: true,
		title: "New request",
		permission: [
			RolesList.RESPONSABLE_COMMERCIAL
		]
	},
	{
		component: <ManagerKPIView/>,
		path: URL.KPI_PATH,
		titleHelloUser: true,
		title: "Manager KPI",
		permission: [
			RolesList.RESPONSABLE_COMMERCIAL
		]
	},
	{
		component: <ResCalendarView/>,
		path: URL.CALENDAR_PATH,
		titleHelloUser: true,
		title: "Res Calendar",
		permission: [
			RolesList.RESPONSABLE_COMMERCIAL
		]
	},
	{
		component: <PrestationsComsRes/>,
		path: URL.PRESTATIONS_PATH,
		titleHelloUser: true,
		title: "Prestations",
		permission: [
			RolesList.RESPONSABLE_COMMERCIAL
		]
	},
	{
		component: <Profile/>,
		path: URL.PROFILE_PATH,
		title: "Profile",
		permission: [
			RolesList.RESPONSABLE_COMMERCIAL
		]
	},
	// Com
	{
		component: <HomeCommercial/>,
		path: URL.HOME_PATH,
		titleHelloUser: true,
		title: "Commercial Home",
		permission: [
			RolesList.COMMERCIAL
		]
	},
	{
		component: <DevisRefonte/>,
		path: URL.COMS_COM_CREATE_DEVIS,
		permission: [
			RolesList.COMMERCIAL
		]
	},
	{
		component: <ComKpisView/>,
		path: URL.KPI_PATH,
		titleHelloUser: true,
		title: "Commercial KPI",
		permission: [
			RolesList.COMMERCIAL
		]
	},
	{
		component: <PastStudiesView/>,
		path: URL.COMS_COM_PAST_STUDIES,
		titleHelloUser: true,
		title: "Past studies",
		permission: [
			RolesList.COMMERCIAL
		]
	},
	{
		component: <Devis/>,
		path: URL.COMS_COM_FORM_DEVIS,
		titleHelloUser: true,
		title: "Création de devis",
		permission: [
			RolesList.COMMERCIAL
		]
	},
	{
		component: <ArchiveCom/>,
		titleHelloUser: true,
		path: URL.COMS_COM_ARCHIVE,
		title: "Com Archive",
		permission: [
			RolesList.COMMERCIAL
		]
	},
	{
		component: <ComCalendarView/>,
		titleHelloUser: true,
		path: URL.CALENDAR_PATH,
		title: "Com Calendar",
		permission: [
			RolesList.COMMERCIAL
		]
	},
	{
		component: <Profile/>,
		path: URL.PROFILE_PATH,
		title: "Profile",
		permission: [
			RolesList.COMMERCIAL
		]
	},
	// END Commercial

	// Admin
	{
		component: <HomeAdmin/>,
		titleHelloUser: true,
		path: URL.HOME_PATH,
		title: "Home admin",
		permission: [
			RolesList.ADMIN
		],
	},
	{
		component: <MarketPlace/>,
		titleHelloUser: true,
		path: URL.ADMIN_MARKET_PLACE_PATH,
		title: "Market place admin",
		permission: [
			RolesList.ADMIN
		],
	},
	{
		component: <KpiAdmin/>,
		titleHelloUser: true,
		path: URL.KPI_PATH,
		title: "KPI admin",
		permission: [
			RolesList.ADMIN
		],
	},
	{
		component: <AdminCalendar/>,
		titleHelloUser: true,
		path: URL.CALENDAR_PATH,
		title: "Calendar admin",
		permission: [
			RolesList.ADMIN
		],
	},
	{
		component: <ProjectsAdmin/>,
		titleHelloUser: true,
		path: URL.ADMIN_PROJECTS_PATH,
		title: "Projects admin",
		permission: [
			RolesList.ADMIN
		],
	},
	{
		component: <UsersAdmin/>,
		titleHelloUser: true,
		path: URL.ADMIN_USERS_PATH,
		title: "Users admin",
		permission: [
			RolesList.ADMIN
		],
	},
	{
		component: <Partners/>,
		titleHelloUser: true,
		path: URL.ADMIN_PARTNERS_PATH,
		title: "Partners admin",
		permission: [
			RolesList.ADMIN
		],
	},
	{
		component: <PartnersStats/>,
		titleHelloUser: true,
		path: URL.ADMIN_PARTNERS_STAT_PATH,
		title: "Partners stat admin",
		permission: [
			RolesList.ADMIN
		],
	},
	{
		component: <AdminCctpView/>,
		titleHelloUser: true,
		path: URL.ADMIN_CCTP_PATH,
		title: "CCTP admin",
		permission: [
			RolesList.ADMIN
		],
	},
	{
		component: <AdminDpgfView/>,
		titleHelloUser: true,
		path: URL.ADMIN_DPGF_PATH,
		title: "DPGF admin",
		permission: [
			RolesList.ADMIN
		],
	},
	{
		component: <AdminBills/>,
		titleHelloUser: true,
		path: URL.ADMIN_BILL_PATH,
		title: "Facturation",
		permission: [
			RolesList.ADMIN
		],
	},
	{
		component: <Profile/>,
		path: URL.PROFILE_PATH,
		titleHelloUser: false,
		title: "Profile",
		permission: [
			RolesList.ADMIN
		]
	},
	{
		component: <ChatbotAdmin/>,
		path: URL.ADMIN_CHATBOT_PATH,
		titleHelloUser: true,
		title: "Chatbot",
		permission: [
			RolesList.ADMIN
		]
	},
	{
		component: <CustomerViewAdmin/>,
		path: URL.ADMIN_CUSTOMER_VIEW_PATH,
		titleHelloUser: true,
		title: "Customer view",
		permission: [
			RolesList.ADMIN
		]
	},
	{
		component: <DocumentViewAdmin/>,
		path: URL.ADMIN_DOCUMENT_VIEW_PATH,
		titleHelloUser: false,
		title: "Document view",
		permission: [
			RolesList.ADMIN
		]
	},
	// END Admin

	// Architect
	{
		component: <HomeArchitect/>,
		titleHelloUser: true,
		path: URL.HOME_PATH,
		title: "Home architect",
		permission: [
			RolesList.ARCHITECTE
		],
	}, {
		component: <CustomerListingArch/>,
		titleHelloUser: false,
		path: URL.ARCHITECTE_CLIENTS_PATH,
		title: "Clients architect",
		permission: [
			RolesList.ARCHITECTE,
		],
	}, {
		component: <CustomerListingViewIng/>,
		titleHelloUser: false,
		path: URL.ARCHITECTE_CLIENTS_PATH,
		title: "Clients architect",
		permission: [
			RolesList.MANAGER_INGENIEUR,
			RolesList.AFFAIRES_INGENIEUR,
			RolesList.DIAGNOSTIQUEUR_INGENIEUR,
			RolesList.VERIFICATEUR_INGENIEUR,
		],
	},

	{
		component: <CustomerDetailArch/>,
		titleHelloUser: false,
		path: URL.ARCHITECT_CLIENTS_VIEW_PATH,
		title: "Client card architect",
		permission: [
			RolesList.ARCHITECTE
		],
	},
	{
		component: <ProfileArchitect/>,
		path: URL.PROFILE_PATH,
		titleHelloUser: false,
		title: "Profile",
		permission: [
			RolesList.ARCHITECTE
		]
	},

	// CUSTOMER
	{
		component: <HomeCustomer/>,
		titleHelloUser: true,
		path: URL.HOME_PATH,
		title: "Home client",
		permission: [
			RolesList.CUSTOMER
		],
	},
	{
		component: <MarketplaceCustomer/>,
		titleHelloUser: false,
		path: URL.CUSTOMER_MARKETPLACE_PATH,
		title: "Marketplace",
		permission: [
			RolesList.CUSTOMER
		],
	},
	{
		component: <ProfileCustomer/>,
		titleHelloUser: false,
		path: URL.PROFILE_PATH,
		title: "Profil",
		permission: [
			RolesList.CUSTOMER
		],
	},
	// END CUSTOMER

	// PROJECT STAGES
	{
		component: <ProjectStageManager/>,
		titleHelloUser: false,
		path: URL.PROJECT_STEP_INITIAL_PATH,
		title: "",
		permission: [
			RolesList.CUSTOMER,
			RolesList.ARCHITECTE,
		]
	},
	{
		component: <ProjectMedias/>,
		titleHelloUser: false,
		path: URL.PROJECT_DOCUMENTS_PATH,
		title: "",
		permission: [
			RolesList.CUSTOMER,
			RolesList.ARCHITECTE,
			RolesList.MANAGER_INGENIEUR,
			RolesList.AFFAIRES_INGENIEUR,
			RolesList.DIAGNOSTIQUEUR_INGENIEUR,
			RolesList.VERIFICATEUR_INGENIEUR,
			RolesList.ASSISTANT_INGENIEUR,
			RolesList.DIRECTEUR_INGENIEUR,
			RolesList.RESPONSABLE_INGENIEUR
		]
	},
	{
		component: <ProjectStageStart/>,
		titleHelloUser: false,
		path: URL.PROJECT_STEP_START_PATH,
		title: "",
		permission: [
			RolesList.CUSTOMER,
			RolesList.ARCHITECTE,
		]
	},
	{
		component: <ProjectStageSignature/>,
		titleHelloUser: false,
		path: URL.PROJECT_STEP_SIGNATURE_PATH,
		title: "",
		permission: [
			RolesList.CUSTOMER,
			RolesList.ARCHITECTE,
		]
	},
	{
		component: <ProjectStageDeposit/>,
		titleHelloUser: false,
		path: URL.PROJECT_STEP_DEPOSIT_PATH,
		title: "",
		permission: [
			RolesList.CUSTOMER,
			RolesList.ARCHITECTE,
		]
	},
	{
		component: <ProjectStageVisite/>,
		titleHelloUser: false,
		path: URL.PROJECT_STEP_VISITE_PATH,
		title: "",
		permission: [
			RolesList.CUSTOMER,
			RolesList.ARCHITECTE,
		]
	},
	{
		component: <ProjectStageVisiteMarketplace/>,
		titleHelloUser: false,
		path: URL.PROJECT_STEP_VISITE_MARKETPLACE_PATH,
		title: "",
		permission: [
			RolesList.CUSTOMER,
			RolesList.ARCHITECTE,
		]
	},
	{
		component: <ProjectStageStudy/>,
		titleHelloUser: false,
		path: URL.PROJECT_STEP_STUDY_PATH,
		title: "",
		permission: [
			RolesList.CUSTOMER,
			RolesList.ARCHITECTE,
		]
	},
	{
		component: <ProjectStagePrincipeStructure/>,
		titleHelloUser: false,
		path: URL.PROJECT_STEP_PRINCIPE_PATH,
		title: "",
		permission: [
			RolesList.CUSTOMER,
			RolesList.ARCHITECTE,
		]
	},
	{
		component: <ProjectStagePayment/>,
		titleHelloUser: false,
		path: URL.PROJECT_STEP_PAYMENT_PATH,
		title: "Paiement final",
		permission: [
			RolesList.CUSTOMER,
			RolesList.ARCHITECTE,
		]
	},
	{
		component: <ProjectStageStudyValidation/>,
		titleHelloUser: false,
		path: URL.PROJECT_STEP_VALIDATION_PATH,
		title: "",
		permission: [
			RolesList.CUSTOMER,
			RolesList.ARCHITECTE,
		]
	},
	{
		component: <ProjectStageRefund/>,
		titleHelloUser: false,
		path: URL.PROJECT_STEP_REFUND_PATH,
		title: "",
		permission: [
			RolesList.CUSTOMER,
			RolesList.ARCHITECTE,
		],
	},
	{
		component: <ProjectStageEnd/>,
		titleHelloUser: false,
		path: URL.PROJECT_STEP_END_PATH,
		title: "",
		permission: [
			RolesList.CUSTOMER,
			RolesList.ARCHITECTE,
		]
	},
	// END PROJECT STAGES

	// INGS

	// ING PROFIL

	{
		component: <ProfileING/>,
		titleHelloUser: true,
		path: URL.PROFILE_PATH,
		title: "Profil - Ingénieur",
		permission: [
			RolesList.DIRECTEUR_INGENIEUR,
			RolesList.RESPONSABLE_INGENIEUR,
			RolesList.MANAGER_INGENIEUR,
			RolesList.AFFAIRES_INGENIEUR,
			RolesList.DIAGNOSTIQUEUR_INGENIEUR,
			RolesList.VERIFICATEUR_INGENIEUR,
			RolesList.ASSISTANT_INGENIEUR
		],
	},

	// STUDY Folder

	{
		component: <StudyFolderIng/>,
		titleHelloUser: true,
		path: URL.STUDY_FOLDER_INTRODUCTION_PATH,
		title: "Dossier d'étude - Introduction",
		permission: [
			RolesList.DIRECTEUR_INGENIEUR,
			RolesList.RESPONSABLE_INGENIEUR,
			RolesList.MANAGER_INGENIEUR,
			RolesList.AFFAIRES_INGENIEUR,
			RolesList.DIAGNOSTIQUEUR_INGENIEUR,
			RolesList.VERIFICATEUR_INGENIEUR,
			RolesList.ASSISTANT_INGENIEUR
		],
	},

	{
		component: <StudyFolderTSR/>,
		titleHelloUser: true,
		path: URL.STUDY_FOLDER_TSR_PATH,
		title: "Dossier d'étude - TSR",
		permission: [
			RolesList.DIRECTEUR_INGENIEUR,
			RolesList.RESPONSABLE_INGENIEUR,
			RolesList.MANAGER_INGENIEUR,
			RolesList.AFFAIRES_INGENIEUR,
			RolesList.DIAGNOSTIQUEUR_INGENIEUR,
			RolesList.VERIFICATEUR_INGENIEUR,
			RolesList.ASSISTANT_INGENIEUR
		],
	},

	{
		component: <StudyFolderTSR2/>,
		titleHelloUser: true,
		path: URL.STUDY_FOLDER_2_TSR_PATH,
		title: "Dossier d'étude - TSR",
		permission: [
			RolesList.DIRECTEUR_INGENIEUR,
			RolesList.RESPONSABLE_INGENIEUR,
			RolesList.MANAGER_INGENIEUR,
			RolesList.AFFAIRES_INGENIEUR,
			RolesList.DIAGNOSTIQUEUR_INGENIEUR,
			RolesList.VERIFICATEUR_INGENIEUR,
			RolesList.ASSISTANT_INGENIEUR
		],
	},

	{
		component: <StudyFolderProjetSheet/>,
		titleHelloUser: true,
		path: URL.STUDY_FOLDER_PROJET_SHEET_PATH,
		title: "Dossier d'étude - Fiche projet",
		permission: [
			RolesList.DIRECTEUR_INGENIEUR,
			RolesList.RESPONSABLE_INGENIEUR,
			RolesList.MANAGER_INGENIEUR,
			RolesList.AFFAIRES_INGENIEUR,
			RolesList.DIAGNOSTIQUEUR_INGENIEUR,
			RolesList.VERIFICATEUR_INGENIEUR,
			RolesList.ASSISTANT_INGENIEUR
		],
	},


	{
		component: <StudyFolderCCTP/>,
		titleHelloUser: true,
		path: URL.STUDY_FOLDER_CCTP_PATH,
		title: "Dossier d'étude - CCTP",
		permission: [
			RolesList.DIRECTEUR_INGENIEUR,
			RolesList.RESPONSABLE_INGENIEUR,
			RolesList.MANAGER_INGENIEUR,
			RolesList.AFFAIRES_INGENIEUR,
			RolesList.DIAGNOSTIQUEUR_INGENIEUR,
			RolesList.VERIFICATEUR_INGENIEUR,
			RolesList.ASSISTANT_INGENIEUR
		],
	},

	{
		component: <StudyFolderDPGF/>,
		titleHelloUser: true,
		path: URL.STUDY_FOLDER_DPGF_PATH,
		title: "Dossier d'étude - DPGF",
		permission: [
			RolesList.DIRECTEUR_INGENIEUR,
			RolesList.RESPONSABLE_INGENIEUR,
			RolesList.MANAGER_INGENIEUR,
			RolesList.AFFAIRES_INGENIEUR,
			RolesList.DIAGNOSTIQUEUR_INGENIEUR,
			RolesList.VERIFICATEUR_INGENIEUR,
			RolesList.ASSISTANT_INGENIEUR
		],
	},
	{
		component: <StudyFolderExecutionClass />,
		titleHelloUser: true,
		path: URL.STUDY_FOLDER_CLASSE_EXECUTION_PATH,
		title: "Dossier d'étude - Classe d'execution",
		permission: [
			RolesList.AFFAIRES_INGENIEUR,
		],
	},
	{
		component: <StudyFolderPRI/>,
		path: URL.STUDY_FOLDER_PRI_PATH,
		title: "Dossier d'étude - PRI",
		permission: [
			RolesList.DIRECTEUR_INGENIEUR,
			RolesList.RESPONSABLE_INGENIEUR,
			RolesList.MANAGER_INGENIEUR,
			RolesList.AFFAIRES_INGENIEUR,
			RolesList.DIAGNOSTIQUEUR_INGENIEUR,
			RolesList.VERIFICATEUR_INGENIEUR,
			RolesList.ASSISTANT_INGENIEUR
		],
	},
	{
		component: <StudyFolderAnnexe/>,
		path: URL.STUDY_FOLDER_ANNEXE_PATH,
		title: "Dossier d'étude - ANNEXE",
		permission: [
			RolesList.DIRECTEUR_INGENIEUR,
			RolesList.RESPONSABLE_INGENIEUR,
			RolesList.MANAGER_INGENIEUR,
			RolesList.AFFAIRES_INGENIEUR,
			RolesList.DIAGNOSTIQUEUR_INGENIEUR,
			RolesList.VERIFICATEUR_INGENIEUR,
			RolesList.ASSISTANT_INGENIEUR
		],
	},
	// ING DIR
	{
		component: <HomeIngDir/>,
		titleHelloUser: false,
		path: URL.HOME_PATH,
		title: "Home director ing",
		permission: [
			RolesList.DIRECTEUR_INGENIEUR
		],
	},
	{
		component: <ApprovalCircuitIngDir/>,
		titleHelloUser: true,
		path: URL.COMS_DIR_VALIDATION_CIRCUIT,
		title: "Approval circuit director ing",
		permission: [
			RolesList.DIRECTEUR_INGENIEUR,
			RolesList.RESPONSABLE_INGENIEUR,
		],
	},
	{
		component: <KpiViewIngDir/>,
		titleHelloUser: false,
		path: URL.KPI_PATH,
		title: "KPI director ing",
		permission: [
			RolesList.DIRECTEUR_INGENIEUR,
			RolesList.RESPONSABLE_INGENIEUR,
		],
	},
	{
		component: <CollaboratorsViewIngDir/>,
		titleHelloUser: false,
		path: URL.ENGINEER_DIRECTOR_COLLABORATORS_PATH,
		title: "Collaborators director ing",
		permission: [
			RolesList.DIRECTEUR_INGENIEUR,
			RolesList.RESPONSABLE_INGENIEUR,
		],
	},
	{
		component: <ObjectifsViewIngDir/>,
		titleHelloUser: false,
		path: URL.ENGINEER_DIRECTOR_OBJECTIFS_PATH,
		title: "Objectifs director ing",
		permission: [
			RolesList.DIRECTEUR_INGENIEUR,
			RolesList.RESPONSABLE_INGENIEUR
		],
	},
	{
		component: <ControleViewIngDir/>,
		titleHelloUser: false,
		path: URL.ENGINEER_DIRECTOR_CONTROLE_PATH,
		title: "Control director ing",
		permission: [
			RolesList.DIRECTEUR_INGENIEUR,
			RolesList.RESPONSABLE_INGENIEUR
		],
	},
	// ING RES
	{
		component: <HomeResponsableIng/>,
		titleHelloUser: false,
		path: URL.HOME_PATH,
		title: "Home responsable ing",
		permission: [
			RolesList.RESPONSABLE_INGENIEUR
		],
	},
	// ING MAN
	{
		component: <ProjectsListIng/>,
		path: URL.ENGINEER_PROJECTS_PATH,
		title: "Projects manager ing",
		permission: [
			RolesList.DIRECTEUR_INGENIEUR,
			RolesList.RESPONSABLE_INGENIEUR,
			RolesList.MANAGER_INGENIEUR,
			RolesList.AFFAIRES_INGENIEUR,
			RolesList.DIAGNOSTIQUEUR_INGENIEUR,
			RolesList.VERIFICATEUR_INGENIEUR,
		],
	},
	{
		component: <CalendarIngManager/>,
		titleHelloUser: true,
		path: URL.ENGINEER_MANAGER_CALENDAR_PATH,
		title: "Calendar manager ing",
		permission: [
			RolesList.MANAGER_INGENIEUR,
		],
	},
	// ING MAN
	{
		component: <HomeIngManager/>,
		titleHelloUser: true,
		path: URL.HOME_PATH,
		title: "Home manager ing",
		permission: [
			RolesList.MANAGER_INGENIEUR,
		],
	},
	//ING PROD
	{
		component: <HomeIngProd/>,
		titleHelloUser: true,
		path: URL.HOME_PATH,
		title: "Home prod ing",
		permission: [
			RolesList.AFFAIRES_INGENIEUR,
			RolesList.VERIFICATEUR_INGENIEUR,
			RolesList.ASSISTANT_INGENIEUR,
		],
	},{
		component: <HomeIngDiag/>,
		titleHelloUser: true,
		path: URL.HOME_PATH,
		title: "Home diag ing",
		permission: [
			RolesList.DIAGNOSTIQUEUR_INGENIEUR,
		],
	},{
		component: <HomeIngProdTaskDetail/>,
		path: URL.ENGINEER_PROD_TASK_DETAIL,
		title: "Home prod ing task detail",
		permission: [
			RolesList.AFFAIRES_INGENIEUR,
			RolesList.DIAGNOSTIQUEUR_INGENIEUR,
			RolesList.VERIFICATEUR_INGENIEUR,
			RolesList.ASSISTANT_INGENIEUR,
		],
	},
	// ALL ING
	{
		component: <CustomerViewIng/>,
		titleHelloUser: false,
		path: URL.ENGINEER_CUSTOMER_VIEW_PATH,
		title: "Customer view ing",
		permission: [
			RolesList.DIRECTEUR_INGENIEUR,
			RolesList.RESPONSABLE_INGENIEUR,
			RolesList.MANAGER_INGENIEUR,
			RolesList.AFFAIRES_INGENIEUR,
			RolesList.DIAGNOSTIQUEUR_INGENIEUR,
			RolesList.VERIFICATEUR_INGENIEUR,
			RolesList.ASSISTANT_INGENIEUR
		],
	}
]
